<template>
  <main class="icon">
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50002 12C9.92947 12.5741 10.4774 13.0492 11.1066 13.3929C11.7358 13.7367 12.4315 13.9411 13.1467 13.9923C13.8618 14.0435 14.5796 13.9404 15.2514 13.6898C15.9231 13.4392 16.5331 13.0471 17.04 12.54L20.04 9.54C20.9508 8.59699 21.4548 7.33397 21.4434 6.02299C21.432 4.71201 20.9062 3.45794 19.9791 2.5309C19.0521 1.60386 17.798 1.07802 16.487 1.06663C15.176 1.05523 13.913 1.55921 12.97 2.47L11.25 4.18M13.5 10C13.0706 9.42587 12.5227 8.95081 11.8935 8.60706C11.2643 8.2633 10.5685 8.05888 9.85337 8.00766C9.13822 7.95645 8.42043 8.05963 7.74867 8.31021C7.07691 8.5608 6.4669 8.95293 5.96002 9.46L2.96002 12.46C2.04923 13.403 1.54525 14.666 1.55665 15.977C1.56804 17.288 2.09388 18.542 3.02092 19.4691C3.94796 20.3961 5.20203 20.922 6.51301 20.9334C7.82399 20.9448 9.08701 20.4408 10.03 19.53L11.74 17.82"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.icon:hover path {
  stroke: var(--primary);
}
</style>