<template>
  <main class="icon">
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6667 1.83333C13.8856 1.61446 14.1455 1.44084 14.4314 1.32239C14.7174 1.20394 15.0239 1.14297 15.3334 1.14297C15.6429 1.14297 15.9494 1.20394 16.2354 1.32239C16.5214 1.44084 16.7812 1.61446 17.0001 1.83333C17.219 2.0522 17.3926 2.31204 17.511 2.598C17.6295 2.88397 17.6904 3.19047 17.6904 3.5C17.6904 3.80953 17.6295 4.11602 17.511 4.40199C17.3926 4.68796 17.219 4.94779 17.0001 5.16666L5.75008 16.4167L1.16675 17.6667L2.41675 13.0833L13.6667 1.83333Z"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.icon:hover path {
  stroke: var(--primary);
}
</style>