<template>
  <main class="icon">
    <svg
      width="23"
      height="22"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0566 12.0988C14.4861 12.6729 15.034 13.1479 15.6632 13.4917C16.2924 13.8355 16.9881 14.0399 17.7033 14.0911C18.4184 14.1423 19.1362 14.0391 19.808 13.7885C20.4797 13.538 21.0897 13.1458 21.5966 12.6388L24.5966 9.63877C25.5074 8.69576 26.0114 7.43275 26 6.12176C25.9886 4.81078 25.4628 3.55671 24.5357 2.62967C23.6087 1.70263 22.3546 1.17679 21.0436 1.1654C19.7326 1.154 18.4696 1.65798 17.5266 2.56877L15.8066 4.27877M12.9436 14.9333C12.5142 14.3592 11.9663 13.8841 11.3371 13.5404C10.7079 13.1966 10.0121 12.9922 9.29697 12.941C8.58183 12.8898 7.86403 12.9929 7.19227 13.2435C6.52051 13.4941 5.9105 13.8862 5.40362 14.3933L2.40362 17.3933C1.49283 18.3363 0.988858 19.5993 1.00025 20.9103C1.01164 22.2213 1.53749 23.4753 2.46453 24.4024C3.39157 25.3294 4.64563 25.8552 5.95661 25.8666C7.2676 25.878 8.53061 25.3741 9.47362 24.4633L11.1836 22.7533M12 4.9321V2.9321M7 9.93211H5M7.91421 5.84631L6.5 4.4321"
        stroke="#667085"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.icon:hover path {
  stroke: var(--primary);
}
</style>